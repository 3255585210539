<template>
	<div class="full-height">
		<table class="table th-left">
			<col width="160px" />
			<col width="auto" />
			<tbody>
			<tr>
				<th>SMS</th>
				<td>
					<select
						v-model="item.sms_code"
						class="input-box width-100"
					>
						<option value="">SMS를 선택하세요</option>
						<option
							v-for="(code, index) in codes.S005.items"
							:key="code.total_code + '_' + index"
							:value="code.code_value"
						>{{ code.code_name }}</option>
					</select>
				</td>
			</tr>
			<tr>
				<th>SMS 명 <label class="radius-10 box pa-0-5" title="SMS명을 작성합니다">?</label></th>
				<td>
					<input v-model="item.sms_name" class="input-box " placeholder="SMS명" maxlength="25" />
				</td>
			</tr>
			<tr>
				<th>SMS 가맹점 명 <label class="radius-10 box pa-0-5" title="SMS사에 등록된 가맹점명을 입력합니다">?</label></th>
				<td>
					<input v-model="item.sms_merch_name" class="input-box " placeholder="가맹점 명" maxlength="25"/>
				</td>
			</tr>
			<tr>
				<th>SMS 가맹점 ID <label class="radius-10 box pa-0-5" title="SMS사에서 발급받은 MID를 입력합니다">?</label></th>
				<td>
					<input v-model="item.sms_merch_id" class="input-box " placeholder="가맹점 ID" maxlength="50" />
				</td>
			</tr>
			<tr>
				<th>SMS KEY <label class="radius-10 box pa-0-5" title="SMS사에서 발급받은 KEY(보유시)를 입력합니다">?</label></th>
				<td>
					<input v-model="item.sms_key" class="input-box " placeholder="SMS KEY" />
				</td>
			</tr>
			<tr>
				<th>발신번호 <label class="radius-10 box pa-0-5" title="SMS사에 등록된 발신번호를 입력합니다">?</label></th>
				<td>
					<input v-model="item.sms_number" class="input-box " placeholder="추가 필드" />
				</td>
			</tr>
			</tbody>
		</table>
		<div class="mt-10">
			<button
				class="bg-identify pa-5-10 mr-10"
				@click="save"
			>저장</button>
			<button
				class="bg-default pa-5-10 mr-10"
				@click="$emit('cancel')"
			>취소</button>
		</div>
	</div>
</template>

<script>
export default{
	name: 'ManagerPgItem'
	, props: ['Axios', 'user', 'codes', 'rules', 'TOKEN', 'uid']
	, data: function(){
		return {
			program: {
				name: 'SMS사 정보 관리'

			}
			, item: {
				sms_code: ''
				, sms_type: ''
				, sms_name: ''
				, sms_merch_id: ''
				, sms_merch_name: ''
			}
			, items_company: []
		}
	}
	, computed: {

	}
	, methods: {
		save: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postSmsItem'
					,data: this.item
				})

				if(result.success){
					this.$emit('success')
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/getSmsItem'
					,data: {
						uid: this.uid
					}
				})

				if(result.success){
					this.item = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {

		if(this.uid){
			await this.getData()
		}
	}
}
</script>